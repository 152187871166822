<template>
  <!-- dialog -->
  <Dialog :is-show="showDialog" :title="dialogTitle" :dialog-width="dialogWidth" :dialog-height="dialogHeight"
          @handle-close="handleClose()"
          @handle-submit="submitForm()">
    <template slot="content">
      <el-form :model="formData" :rules="rules" label-width="auto">
        <!-- 商户信息 -->
        <div>
          <div class="flex items-center sm:flex-row">
            <div class="prefix"></div>
            <span class="ml-2 color-blue">商户信息</span>
          </div>
          <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <el-form-item label="商户名称:"></el-form-item>
            <el-form-item label="商户id:"></el-form-item>
            <el-form-item label="支付订单号:"></el-form-item>
            <el-form-item label="商户订单号:"></el-form-item>
            <el-form-item label="支付金额:"></el-form-item>
            <el-form-item label="手续费:"></el-form-item>
            <el-form-item label="服务费率:"></el-form-item>
            <el-form-item></el-form-item>
          </div>
        </div>

        <!-- 订单信息 -->
        <div>
          <div class="flex items-center sm:flex-row">
            <div class="prefix"></div>
            <span class="ml-2 color-blue">订单信息</span>
          </div>
          <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <el-form-item label="订单状态:"></el-form-item>
            <el-form-item label="支付状态:"></el-form-item>
            <el-form-item label="支付错误码:"></el-form-item>
            <el-form-item label="支付错误描述:"></el-form-item>
            <el-form-item label="订单创建时间:"></el-form-item>
            <el-form-item label="订单更新时间:"></el-form-item>
            <el-form-item label="订单成功时间:"></el-form-item>
            <el-form-item label="订单失败时间:"></el-form-item>
          </div>
        </div>

        <!-- 商品信息 -->
        <div>
          <div class="flex items-center sm:flex-row">
            <div class="prefix"></div>
            <span class="ml-2 color-blue">商品信息</span>
          </div>
          <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <el-form-item label="商品标题:"></el-form-item>
            <el-form-item label="商品描述:"></el-form-item>
            <el-form-item label="支付方式:"></el-form-item>
            <el-form-item label="用户信息:"></el-form-item>
            <el-form-item label="分账状态:"></el-form-item>
            <el-form-item label="分账时间:"></el-form-item>
          </div>
        </div>

      </el-form>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/views/Dialog'

export default {
  components: { Dialog },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 弹窗参数
      dialogTitle: '详情',
      dialogWidth: '700px',
      dialogHeight: 'auto',
      // 提交数据
      formData: {},
      // 规则
      rules: {}
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        this._initDataView()
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('handle-close')
    },
    handleSuccess() {
      this.$emit('handle-success')
    },
    // 初始化数据
    _initDataView() {
      console.log('初始化窗口')
    },
    // 提交接口
    submitForm() {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  padding-left: 1%;
  right: 1%;
  .el-form-item {
    //margin-right: 20px;
    width: 47%;
    margin-bottom: 0px;
    span {
      color: #666666;;
      font-size: 14px;
    }
  }
}

.prefix {
  background-color: #1585FF;
  width: 4px;
  height: 18px;
  border-radius: 2px;
}

.color-blue {
  color: #1585FF;
  font-size: 16px;
}

</style>