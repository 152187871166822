<template>
  <div>
    <div id="header-footer-modal-preview" class="modal">
      <div class="modal__content" style="display: flex; flex-direction: column;" :style="{
        width: dialogWidth,
        height: dialogHeight
      }">
        <!-- header -->
        <div class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
          <!-- 左边 -->
          <div class="font-medium text-base mr-auto" style="align-items: center;">
            <span> {{ title }} </span>
            <span v-if="subTitle" style="color: #1585FF; margin-left: 6px; font-size: 14px;">{{ subTitle }}</span>
          </div>
          <span style="font-size: 18px;" @click="handleClose">
            <i class="el-icon-circle-close"></i>
          </span>
        </div>

        <el-container style="flex: 1; height: 100%; background-color: white;" :style="{
          borderBottomRightRadius: isHideBottom ? '22px' : '',
          borderBottomLeftRadius: isHideBottom ? '22px' : ''
        }">
          <el-main>
            <!-- 插槽 -->
            <slot name="content">
            </slot>
          </el-main>
        </el-container>

        <!-- center -->
<!--        <div class="p-5" style="flex: 1; height: 100%; background-color: red;">-->
<!--          &lt;!&ndash; 插槽 &ndash;&gt;-->
<!--          <slot name="content">-->
<!--          </slot>-->
<!--        </div>-->

        <!-- bottom -->
        <div v-if="!isHideBottom" class="px-5 py-3 text-right border-t border-gray-200" style="background-color: white; border-bottom-right-radius: 22px; border-bottom-left-radius: 22px;">
          <button type="button" class="button w-20 border text-gray-700 mr-1" @click="handleClose"> {{ cancelBtnText }} </button>
          <button type="button" class="button w-20 bg-theme-1 text-white"> {{ confirmBtnText }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 自定义Dialog
 */
export default {
  props: {
    // 控制按钮
    isShow: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: '标题'
    },
    subTitle: {
      type: String,
      default: () => undefined
    },
    // 宽度
    dialogWidth: {
      type: String,
      default: () => '30%'
    },
    // 高度
    dialogHeight: {
      type: String,
      default: () => '70%'
    },
    // 取消按钮的文字
    cancelBtnText: {
      type: String,
      default: () => '取消'
    },
    // 确定按钮的文字
    confirmBtnText: {
      type: String,
      default: () => '确认'
    },
    isHideBottom: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    isShow(val) {
      if (val) {
        cash("#header-footer-modal-preview").modal("show"); // 开启弹窗
      } else {
        cash("#header-footer-modal-preview").modal("hide"); // 关闭弹窗
      }
    }
  },
  created() {
    console.log('init')
    cash("#header-footer-modal-preview").modal("hide"); // 关闭弹窗
  },
  methods: {
    handleClose() {
      this.$emit('handle-close')
    },
    handleSubmit() {
      this.$emit('handle-submit')
    }
  }
}
</script>

<style scoped>
  .modal {
    background: #00000020;
  }
  .modal__content {
    border-radius: 22px;
  }
</style>